import { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import style from './Modal.module.scss';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

const sizesMap = {
  sm: 'max-w-300',
  'md-400': 'max-w-[400px]',
  'md-420': 'max-w-[420px]',
  md: 'max-w-500',
  'md-550': 'max-w-[550px]',
  'md-560': 'max-w-[560px]',
  'md-640': 'max-w-[640px]',
  lg: 'max-w-800',
  xl: 'max-w-1140',
  default: 'max-w-768' //fallback
};

const Modal = ({
  open = false,
  onClose,
  showCloseIcon = true,
  hideGutters = false,
  closeIconHoverText = '',
  closeOnBackdropClick = true,
  customBaseClass = '',
  customContainerClass = '',
  customInnerClass = '',
  customCloseIconClass = '',
  children,
  size = 'default',
  withOutsideCloseIcon,
  showLeftIcon = false,
  leftIcon,
  containerBackgroundTransparent = '',
  customBackdropClassNames = '',
  chin,
  eyebrow = null,
  stickChinToBottom = false,
  customEyeBrowClass = '',
  showNasLogoAtTopLeft = false
}) => {
  const className = 'c-Modal';
  const [_document, setDocument] = useState(null);

  const baseClassNames = classNames(className, style[className], {
    [style[`${className}--open`]]: open
  });
  const backDropFilter = containerBackgroundTransparent
    ? 'backdrop-blur-lg	'
    : '';

  const containerClassNames = classNames(
    `${className}__container`,
    'animate-fadeInModal',
    {
      [style[`${className}__transparent_white_color`]]:
        !!containerBackgroundTransparent
    },
    style[`${className}__container`],
    { [style[`${className}__container__no-gutter`]]: hideGutters },
    sizesMap?.[size],
    {
      [customContainerClass]: !!customContainerClass
    },
    'flex flex-col items-stretch'
  );

  const innerClassNames = classNames(
    style[`${className}__inner`],
    {
      [customInnerClass]: !!customInnerClass
    },
    'flex-grow'
  );

  const backdropClassNames = classNames(
    style[`${className}__backdrop`],
    backDropFilter,
    customBackdropClassNames
  );

  const closeModalIconClassName = classNames(
    `${className}__close-modal top-12 right-12`,
    style[`${className}__close-modal`],
    {
      [`${style[`${className}__outside-close-modal`]}`]:
        withOutsideCloseIcon,
      [customCloseIconClass]: !!customCloseIconClass
    }
  );

  const modalEl = _document?.getElementById('modal');

  const handleBackdropClick = () => {
    if (closeOnBackdropClick && !containerBackgroundTransparent)
      onClose?.();
  };

  useLayoutEffect(() => {
    if (document.body && open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (document.body) return (document.body.style.overflow = null);
    };
  }, [open]);

  // Access document in Next after page renders
  useLayoutEffect(() => {
    setDocument(document);
  }, []);

  const renderCloseIcon = useCallback(
    () =>
      showCloseIcon && (
        <div className={closeModalIconClassName} onClick={onClose}>
          {closeIconHoverText ? (
            <div className={style[`${className}__tooltip`]}>
              <span className={style[`${className}__tooltiptext`]}>
                {closeIconHoverText}
              </span>
              <Icon name="x-close" width={18} height={18} />
            </div>
          ) : (
            <NPLButton
              dataTestId="close-modal-button"
              hierarchy="plain"
              leadIcon="x-close"
              rounded
              size="md"
              isSecondary
            />
          )}
        </div>
      ),
    [closeIconHoverText, closeModalIconClassName, onClose, showCloseIcon]
  );

  const renderLeftIcon = useCallback(
    () =>
      showLeftIcon && (
        <div
          className={`${className}__left-icon ${
            style[`${className}__left-icon`]
          }`}>
          {leftIcon}
        </div>
      ),
    [showLeftIcon, leftIcon]
  );

  if (!modalEl) return null;
  return createPortal(
    <div
      className={`${baseClassNames} ${customBaseClass}`}
      data-testid="modal">
      <div className={backdropClassNames} onClick={handleBackdropClick} />
      {showNasLogoAtTopLeft && (
        <div className="fixed left-24 top-24">
          <Icon
            path="logo"
            name="nas-io-logo-with-text-grayscale"
            width={116}
            height={24}
          />
        </div>
      )}
      {withOutsideCloseIcon && renderCloseIcon()}
      <div className={`${containerClassNames} relative`}>
        {eyebrow && (
          <div
            className={`${className}__eyebrow ${
              style[`${className}__eyebrow`]
            } ${customEyeBrowClass}`}>
            {eyebrow}
          </div>
        )}
        {showLeftIcon && renderLeftIcon()}
        {!withOutsideCloseIcon && renderCloseIcon()}

        <div className={`${innerClassNames}`}>{children}</div>
        {chin && (
          <div
            className={`${className}__chin  ${
              style[`${className}__chin`]
            } ${stickChinToBottom && 'absolute bottom-0 w-full'}`}>
            {chin}
          </div>
        )}
      </div>
    </div>,
    modalEl
  );
};

// No need to pass in onClose function if showCloseIcon is false, by default it is true
Modal.propTypes = {
  size: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  hideGutters: PropTypes.bool,
  children: PropTypes.any.isRequired,
  closeIconHoverText: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool,
  customBaseClass: PropTypes.string,
  customContainerClass: PropTypes.string.isRequired,
  customInnerClass: PropTypes.string.isRequired,
  withOutsideCloseIcon: PropTypes.bool,
  showLeftIcon: PropTypes.bool,
  leftIcon: PropTypes.object,
  customBackdropClassNames: PropTypes.string,
  chin: PropTypes.object,
  stickChinToBottom: PropTypes.bool
};

export default Modal;
